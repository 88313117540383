import React from 'react';
import { motion } from 'framer-motion';
import Logo from '../assets/ocullo_logo.png';

const Loader = () => {
  return (
    <div className='h-screen flex justify-center items-center bg-stone-900 font-["Poppins"]'>
      <motion.img
        src={Logo}
        alt="Loading..."
        className='w-60'
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 2, ease: "easeInOut" }} // Adjust duration and easing as needed
      />
    </div>
  );
}

export default Loader;
