import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './components/Home';
import Error from './components/Error';
import { SocketProvider } from './context/SocketContext';
import AccessDeny from './components/AccessDeny';
import { useState, useEffect } from 'react';
import Loader from './components/Loader';


function App() {

  const [isMobile, setIsMobile] = useState(false);  
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Simulate a delay (e.g., fetching data, loading resources)
    setTimeout(() => {
      setLoading(false);
    }, 2000); // Set your desired delay (2 seconds in this example)
  }, []);

  useEffect(() => {
    const handleResize = () => {
      const screenWidth = window.innerWidth;
      // Adjust the width to your requirement for mobile and tablet
      if (screenWidth <= 768) {
        setIsMobile(true);
      } else {
        setIsMobile(false);
      }
    };

    // Initial check
    handleResize();

    // Listen for resize events
    window.addEventListener("resize", handleResize);

    // Clean up the event listener
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  if (loading) {
    return <Loader />;
  }

  return (
    <Router>
     <SocketProvider>
     <div>
        <Routes>
          <Route path="/" element={isMobile? <AccessDeny /> : <Home />} /> 
          <Route path='*' element={<Error />} />
        </Routes>
      </div>
     </SocketProvider>
    </Router>
  );
}

export default App;





